import { actionTypes } from 'actions/home';

import produce from 'immer';

const getInitialState = () => ({
  displayFavoritesOnly: false,
  facetSorted: { key: 'aggregates_update_date', ascending: false },
});

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DISPLAY_FAVORITES_ONLY:
      draft.displayFavoritesOnly = !draft.displayFavoritesOnly;
      break;
    case actionTypes.SORT_VIEW_FACET:
      draft.facetSorted = action.facetSorted;
      break;
    default:
      break;
  }
  return draft;
}, getInitialState());
