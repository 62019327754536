import { createSlice } from '@reduxjs/toolkit';

export const StateStatus = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
};
export function includesAll(textToCheck, ...texts) {
  return texts.every((text) => textToCheck.includes(text));
}

export function isFunction_(check) {
  return {}.toString.call(check) === '[object Function]';
}

const { PENDING, FULFILLED, REJECTED } = StateStatus;

export const createAPISlice = (options, apiSliceOptions) => {
  const keys = apiSliceOptions?.keys ?? null;
  const createSliceFunc = apiSliceOptions?.createSliceOverwrite ?? createSlice;
  const identifier = apiSliceOptions?.identifier ?? ':load';
  const createMatch = (status) => (action) =>
    includesAll(action.type, status, options.name, identifier);

  const createStateSet =
    (status, key = 'state') =>
    (state) => {
      state[key] = status;
    };

  return createSliceFunc({
    ...options,
    extraReducers: (builder) => {
      // Enrich final builder with the one the user created
      if (options.extraReducers && isFunction_(options.extraReducers)) {
        options.extraReducers(builder);
      } else if (options.extraReducers) {
        Object.keys(options.extraReducers).forEach((reducerName) =>
          builder.addCase(reducerName, options.extraReducers[reducerName])
        );
      }
      // Add matches for automatic loading state
      if (keys) {
        keys.forEach((thunkName) =>
          builder
            .addMatcher(
              createMatch(`${thunkName}:load/pending`),
              createStateSet(PENDING, thunkName)
            )
            .addMatcher(
              createMatch(`${thunkName}:load/fulfilled`),
              createStateSet(FULFILLED, thunkName)
            )
            .addMatcher(
              createMatch(`${thunkName}:load/rejected`),
              createStateSet(REJECTED, thunkName)
            )
        );
      } else {
        builder
          .addMatcher(createMatch('/pending'), createStateSet(PENDING))
          .addMatcher(createMatch('/fulfilled'), createStateSet(FULFILLED))
          .addMatcher(createMatch('/rejected'), createStateSet(REJECTED));
      }
    },
  });
};

export default createAPISlice;
