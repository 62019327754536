import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';

import { removeHtmlTags } from 'utils/helpers';
import renderHtml from 'utils/html';

import {
  campaignConfigurationDiffSelectorFactory,
  campaignCustomizationSelectorFactory,
  campaignSelector,
} from './campaign';

const TEXT_SURVEY_QUESTION_TYPE = {
  key: 'TextSurveyQuestion',
  value: 'TextSurveyQuestion',
  text: msg({ id: 'text-survey-question' }),
  icon: 'font',
};

const MULTIPLE_CHOICE_SURVEY_QUESTION_TYPE = {
  key: 'MultiChoiceSurveyQuestion',
  value: 'MultiChoiceSurveyQuestion',
  text: msg({ id: 'categorical-survey-question' }),
  icon: 'list',
};
export const QUESTION_TYPES = [
  MULTIPLE_CHOICE_SURVEY_QUESTION_TYPE,
  TEXT_SURVEY_QUESTION_TYPE,
  {
    key: 'NPS',
    value: 'NPS',
    text: msg({ id: 'NPS' }),
    disabled: true,
    icon: 'star',
  },
  {
    key: 'CSAT',
    value: 'CSAT',
    text: msg({ id: 'CSAT' }),
    disabled: true,
    icon: 'star',
  },
  {
    key: 'CES',
    value: 'CES',
    text: msg({ id: 'CES' }),
    disabled: true,
    icon: 'star',
  },
];
export const AUTO_NAVIGATE_SPEED_OPTIONS = [
  {
    value: 1,
    label: msg({ id: 'immediate' }),
  },
  {
    value: 400,
    label: msg({ id: 'fast' }),
  },
  {
    value: 900,
    label: msg({ id: 'slow' }),
  },
];

export const getQuestionType = (questionType) =>
  QUESTION_TYPES.find(({ value }) => value === questionType);

const surveyCustomizationElementSelectorFactory =
  (key, elementKey = null, defaultValue = null) =>
  (campaignId, useDiff = false) =>
    createSelector(
      (useDiff ? campaignConfigurationDiffSelectorFactory : campaignSelector)(
        campaignId
      ),
      (configuration) => {
        const element = configuration?.[key] || defaultValue;
        if (elementKey) {
          return element?.[elementKey] || defaultValue;
        }
        return element;
      }
    );

export const surveyQuestionsSelector =
  surveyCustomizationElementSelectorFactory('questions', null, []);

export const displayHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('display_home_screen', null, false);

export const endScreenSelector = surveyCustomizationElementSelectorFactory(
  'end_screen',
  null,
  {}
);

export const taglineHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'tagline', '');
export const titleHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'title', '');

export const descriptionHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'description', '');

export const ctaTextHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'button_label', '');

export const titleEndScreenSelector = surveyCustomizationElementSelectorFactory(
  'end_screen',
  'title',
  ''
);
export const descriptionEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'description', '');
export const endScreenIllustrationSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'illustration', '');

export const showCtaEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'show_button', false);
export const ctaLabelEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'button_label', '');
export const ctaUrlEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'button_url', '');

export const autoNavigateSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'auto_navigate',
  false
);
export const navigationSpeedSelector =
  surveyCustomizationElementSelectorFactory(
    'theme',
    'navigation_speed',
    AUTO_NAVIGATE_SPEED_OPTIONS[1].value
  );

export const primaryColorSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'primary',
  ''
);

export const mainLogoSelector = surveyCustomizationElementSelectorFactory(
  'logo_url',
  null,
  ''
);

export const previousLogoSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (configuration) => configuration.logo_url
  );

export const isRelaxedSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'relaxed',
  false
);

export const dpoEmailSelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'dpo_email',
  ''
);
export const privacyPolicySelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'organization_policy_url',
  ''
);

export const questionSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.find(
        ({ id: questionUniqueId }) => questionUniqueId === questionId
      ) || null
  );

/**
 * Create a selector for conditioned questions by a common question.
 *
 * @param {string} campaignId - The ID of the campaign.
 * @param {string} questionId - The ID of the question to filter by condition.
 * @param {boolean} [useDiff=false] - Flag to determine if differential data should be used.
 * @returns {Function} A selector function that filters questions based on conditions.
 */
export const conditionedQuestionSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.filter(({ conditions }) =>
        conditions?.some(
          ({ question: { id: conditionQuestionId } }) =>
            conditionQuestionId === questionId
        )
      ) || []
  );

export const questionAnswerItemsSelector =
  (campaignId, useDiff = false) =>
  (questionId) =>
    createSelector(
      questionSelectorFactory(campaignId, questionId, useDiff),
      (question) =>
        question?.options?.map(({ id: iterQuestionId, display_name }) => ({
          key: iterQuestionId,
          value: iterQuestionId,
          content: display_name?.trim() ? removeHtmlTags(display_name) : '-',
        })) || []
    );

export const conditionQuestionItemsSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false,
  // Do not return text questions
  categoricalOnly = false,
  filterIds = []
) =>
  createSelector(surveyQuestionsSelector(campaignId, useDiff), (questions) => {
    const currentQuestionIndex = questions.findIndex(
      ({ id: questionUniqueId }) => questionUniqueId === questionId
    );
    const alreadyInConditions =
      questions[currentQuestionIndex]?.conditions?.map(
        ({ question }) => question
      ) || [];
    return (
      // Select only questions that are before the current question
      questions
        .slice(0, currentQuestionIndex)
        .filter(
          ({ id: questionUniqueId, type: questionType }) =>
            questionUniqueId &&
            !alreadyInConditions.includes(questionUniqueId) &&
            (!categoricalOnly ||
              !['TextSurveyQuestion'].includes(questionType)) &&
            !filterIds.includes(questionUniqueId)
        )
        .map(({ index, id, title }) => {
          const text = `${index}. ${removeHtmlTags(title)}`;
          return {
            key: id,
            value: id,
            text,
            content: text,
          };
        }) || []
    );
  });

export const isFeedbackCampaignConfiguration = (campaign) =>
  !!campaign?.ontology || false;

export const isFeedbackCampaignSelector = (campaignId) =>
  createSelector(campaignSelector(campaignId), isFeedbackCampaignConfiguration);

// Survey campaigns
export const surveyQuestionsItemsSelectorFactory = (
  campaignId,
  useDiff = false,
  noTextQuestion = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions
        ?.filter(
          (item) => !noTextQuestion || item.type !== 'TextSurveyQuestion'
        )
        .map(({ id, title, ...questionProps }, i) => ({
          // We use position as an alternative to id, since id does not exist at first when
          // question is created
          key: i,
          value: id,
          text: removeHtmlTags(`${i + 1}. ${title}`),
          tagSetId: questionProps.tag_set?.id,
        })) || []
  );

export const questionLabelFormatterSelectorFactory = (
  campaignId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    campaignSelector(campaignId),
    (questions) => (questionId) => {
      const questionIndex = questions?.findIndex(({ id }) => id === questionId);
      return questions?.[questionIndex]
        ? `${questionIndex + 1}. ${removeHtmlTags(
            questions[questionIndex].title
          )}`
        : '-';
    }
  );

const answerLabelsSelector = (campaignId) =>
  createSelector(campaignSelector(campaignId), (configuration) =>
    configuration.questions?.reduce((acc, question) => {
      question.options?.forEach((option) => {
        acc[option.id] = option;
      });
      return acc;
    }, {})
  );
export const questionAnswerLabelFormatterSelectorFactory = (campaignId) =>
  createSelector(
    answerLabelsSelector(campaignId),
    (answerLabels) =>
      function answerLabelSelector(cellKey) {
        return (
          (typeof answerLabels[cellKey]?.display_name === 'string' &&
            renderHtml(answerLabels[cellKey]?.display_name)) ||
          cellKey?.map?.(
            (key) =>
              // renderHtml(answerLabels[key]?.display_name, null, null, key) ||
              removeHtmlTags(answerLabels[key]?.display_name) || '-'
          ) ||
          i18n._(msg({ id: 'not-completed' }))
        );
      }
  );

export const newQuestionSelector = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => customization?.newQuestion
  );

export const questionTitleMapSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaignConfiguration) =>
      campaignConfiguration?.questions?.reduce((acc, question) => {
        acc[question.id] = question.title;
        return acc;
      }, {}) || {}
  );
