import { createLogger } from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from 'redux-persist';

import { configureStore as baseConfigureStore } from '@reduxjs/toolkit';
import appReducers from 'reducers';

import * as Sentry from '@sentry/react';

import config from 'config';

const isDevelopment = process.env.NODE_ENV === 'development';

const ignorePersistTypesMiddleware = (middlewares) => (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(middlewares);

const configureStore = () => {
  let middlewares = [];

  if (config.logEnabled) {
    middlewares = [...middlewares, createLogger()];
  }
  const store = baseConfigureStore({
    reducer: appReducers,
    middleware: ignorePersistTypesMiddleware(middlewares),
    devTools: isDevelopment,
    enhancers: !isDevelopment ? [Sentry.createReduxEnhancer({})] : [],
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
