import { t } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';
import { actionTypes } from 'actions/entities';
import {
  EMPTY_CATEGORY_LABEL,
  addEmptyItem,
  productHierarchyLabelFormatter,
  sourceLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { getLanguageInfo } from 'reducers/locale';
import { createLoadingSelector } from 'reducers/ui';

import * as svars from 'assets/style/variables';

export const entityLabelFormatter = (state, entityType, id) => {
  let text;
  if (id === EMPTY_CATEGORY_LABEL) return t({ id: 'not-completed' });
  switch (entityType) {
    case 'concept':
      text = state.concepts?.[id]?.name || id;
      break;
    case 'ontologyConcepts':
      text = state.ontologies?.[id]?.name;
      break;

    case 'tag':
    case 'tags':
      text = state.tags[id]?.name;
      break;
    case 'tag_set':
    case 'tagSet':
      text = state.tagSets[id]?.name;
      break;
    case 'ontology':
    case 'ontology_name':
    case 'db_ontology':
      // Return ontology label based on (technical) `ontologyName`
      text = state.ontologyLabels?.[id]?.label;
      break;
    case 'productHierarchyGroup':
    case 'productHierarchyGroups':
      text = state.productHierarchyGroups?.[id]?.name;
      break;
    case 'product_hierarchy':
    case 'productHierarchy':
    case 'productHierarchies':
      text = productHierarchyLabelFormatter(state.productHierarchies, id);
      break;
    case 'sourceGroup':
      text = state.sourceGroups?.[id]?.name;
      break;
    case 'source':
    case 'sources':
      text = sourceLabelFormatter(state.sources, id);
      break;
    default:
      text = '-';
  }
  return text;
};

export const entitiesSelector = (state) => state.entities;

export const entityLabelFormatterSelector = createSelector(
  entitiesSelector,
  (entities) => (entityType, id, meta) =>
    entityLabelFormatter(entities, entityType, id, meta)
);

const tagsSelector = (state) => state.entities.tags;

export const ontologiesSelector =
  (ontologyId = null) =>
  (state) =>
    ontologyId
      ? { [ontologyId]: state.entities.ontologies[ontologyId] }
      : state.entities.ontologies;

export const ontologyLabelsSelector = (state) => state.entities.ontologyLabels;
export const ontologyLabelsItemsSelector = createSelector(
  ontologyLabelsSelector,
  (ontologyLabels) =>
    Object.entries(ontologyLabels).map(([ontologyId, { label }]) => ({
      key: ontologyId,
      text: label,
      value: ontologyId,
    }))
);

export const conceptsSelector =
  (conceptId = null) =>
  (state) =>
    conceptId
      ? { [conceptId]: state.entities.concepts[conceptId] }
      : state.entities.concepts;

export const conceptsItemsSelector = (conceptId = null) =>
  createSelector(
    conceptsSelector(conceptId),
    (concepts) =>
      Object.entries(concepts).map(([id, { name }]) => ({
        key: id,
        text: name,
        value: id,
      })) || null
  );

export const defaultOntologyIdSelector = (conceptId) =>
  createSelector(
    conceptsSelector(),
    ontologyLabelsSelector,
    (concepts, ontologyLabels) =>
      concepts?.[conceptId]?.ontologyId ||
      (Object.keys(ontologyLabels) === 1 &&
        Object.values(ontologyLabels)[0].label) ||
      null
  );

export const tagLabelFormatterSelector = createSelector(
  tagsSelector,
  (tags) => (id) => tags[id]?.name || null
);

export const tagColorFormatterSelector = createSelector(
  tagsSelector,
  (tags) => (id) =>
    (id === EMPTY_CATEGORY_LABEL && svars.emptyCategoryGradientUrl) ||
    tags[id]?.color ||
    null
);

export const conceptColorFormatterSelector = createSelector(
  conceptsSelector(),
  (concepts) => (conceptId) =>
    svars.CONCEPTS_LEVEL_COLOR_MAP[concepts?.[conceptId]?.level] || null
);

export const tagValueFormatterSelector = createSelector(
  tagsSelector,
  (tags) => (id) =>
    (id === EMPTY_CATEGORY_LABEL && 0) || tags[id]?.value || null
);

export const productHierarchiesItemsSelector = createSelector(
  (state) => state.entities.productHierarchiesItems,
  (productHierarchiesItems) =>
    // eslint-disable-next-line no-unused-vars
    productHierarchiesItems.map(({ label, ...other }) => other)
);

export const productHierarchyGroupsSelector = (state) =>
  state.entities.productHierarchyGroups;

export const productHierarchyGroupsItemsSelector = (filterOutId) =>
  createSelector(
    productHierarchyGroupsSelector,
    (productHierarchyGroups) =>
      Object.entries(productHierarchyGroups)
        .filter(([id]) => !filterOutId || id !== filterOutId)
        .map(([id, { name }]) => ({
          key: id,
          value: id,
          text: name,
        })) || null
  );

export const sourceGroupsItemsSelector = createSelector(
  (state) => state.entities.sourceGroups,
  (sourceGroups) =>
    Object.entries(sourceGroups).map(([id, { name }]) => ({
      key: id,
      value: id,
      text: name,
    })) || null
);

export const restitutionLanguagesItemsSelector = createSelector(
  (state) => state.entities.restitutionLanguages,
  (restitutionLanguages) =>
    restitutionLanguages.map((language) => {
      const languageInfo = getLanguageInfo(language);
      return {
        key: language,
        value: language,
        text: languageInfo?.text,
        flag: languageInfo?.flag,
      };
    }) || null
);

export const ontologiesItemsSelector = (filteredOntologyId = null) =>
  createSelector(
    ontologiesSelector(filteredOntologyId),
    ontologyLabelsSelector,
    (ontologies, ontologyLabels) => {
      const selectedOntologies = filteredOntologyId
        ? [[filteredOntologyId, ontologies[filteredOntologyId]]]
        : Object.entries(ontologies);
      const conceptItems = [];
      selectedOntologies.forEach(([ontologyId, concept]) => {
        Object.keys(concept).forEach((conceptId) => {
          conceptItems.push({
            key: `${conceptId}-${concept[conceptId].name}`,
            value: conceptId,
            label:
              Object.keys(ontologyLabels).length > 1
                ? `${concept[conceptId].name} (${
                    ontologyLabels[ontologyId]?.label || ''
                  })`
                : concept[conceptId].name,
          });
        });
      });

      return conceptItems;
    }
  );

export const productHierarchiesLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_PRODUCT_HIERARCHIES_REQUEST,
]);
export const facetEntitiesLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_FACET_ENTITIES_REQUEST,
]);

export const ontologyLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_ONTOLOGIES_REQUEST,
]);

export const usersSelector = (state) => state.entities.users;

export const formatUserItem = ({ id, pseudonym }) => ({
  key: id,
  text: pseudonym,
  value: id,
  icon: 'user',
});

export const usersItemsSelectorFactory = (withEmptyItem) =>
  createSelector(usersSelector, (users) => {
    const items = users?.map(formatUserItem);
    return withEmptyItem ? addEmptyItem(items) : items;
  });

export const usersIsLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_USERS_REQUEST,
]);

export const userLabelFormatterSelector = createSelector(
  usersSelector,
  (users) => (id) => {
    const selected = users.find((user) => user.id === id);
    return selected?.pseudonym || selected?.username || '-';
  }
);

export const adminEntitiesSelector = (state) => state.entities.adminEntities;
