import { actionTypes as campaignActionTypes } from 'actions/campaign';
import { actionTypes } from 'actions/entities';
import { actionTypes as entityGroupsActionTypes } from 'actions/entity_groups';
import { actionTypes as userActionTypes } from 'actions/user';
import { actionTypes as viewActionTypes } from 'actions/view';
import { isFeedbackCampaignConfiguration } from 'selectors/survey';

import produce from 'immer';

import { competitionEntityColors } from 'assets/style/variables';

const getInitialState = () => ({
  // Entities are all items that can be referenced by an id in other stores
  // This store is shared so entities are easily accessible and loaded only once
  ontologies: {},
  ontologiesById: {},
  ontologyLabels: {},
  concepts: {
    // Concept map using db concept id as key (i.e. avoids to have to provide ontology name)
  },
  // Map source ids to source information
  sources: {},
  // Map source group ids to source group information
  sourceGroups: {},
  // Map product hierarchy ids to product hierarchy information
  productHierarchies: {},
  productHierarchiesItems: [],
  // Product hierarchy group ids to product hierarchy group information
  productHierarchyGroups: {},
  facetEntityItems: {},

  // Map tag sets
  tagSets: {},

  // Map tags
  tags: {},

  // Users (used for annotations)
  users: [],

  // Restitution languages authorized
  restitutionLanguages: [],
  // Keep a list of already loaded entities to be used by methods `maybeFetch...`
  loaded: [],
  adminEntities: {},
});

export /**
 * Format ontology to its legacy (i.e. before moving ontologies to db) format.
 * This is necessary as multiple features rely on this format (validation, entity management).
 * @todo remove this method and switch to a db based format.
 *
 * @param {*} responseData the ontology response data
 * @return {*} the mapped ontologies and ontology labels.
 */
const getLegacyOntologyFormat = (responseData) => {
  const ontologies = {};
  const ontologyLabels = {};
  responseData.forEach(({ name, display_name, concepts }) => {
    ontologies[name] = {};
    concepts.forEach((concept) => {
      ontologies[name][concept.id] = concept;
    });
    ontologyLabels[name] = { label: display_name };
  });
  return [ontologies, ontologyLabels];
};

export const getOntologyIdFromConceptFormat = (responseData) => {
  const ontologies = {};
  const ontologyLabels = {};
  responseData.forEach(({ id, name, display_name, concepts }) => {
    ontologies[id] = {};
    concepts.forEach((concept) => {
      ontologies[id][concept.id] = { ...concept, ontologyName: name };
      ontologyLabels[id] = { label: display_name };
    });
  });

  return [ontologies, ontologyLabels];
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ONTOLOGIES_SUCCESS:
      draft.ontologies = {};
      [draft.ontologies, draft.ontologyLabels] = getOntologyIdFromConceptFormat(
        action.data
      );
      // Also store basic format
      // TODO : replace legacy format usage by this one
      action.data.forEach((ontology) => {
        draft.ontologiesById[ontology.id] = ontology;
        ontology.concepts.forEach(({ id, ...concept }) => {
          draft.concepts[id] = { ...concept, ontologyId: ontology.id };
        });
      });
      draft.loaded.push(action.cacheKey);
      break;
    case actionTypes.FETCH_FACET_ENTITIES_REQUEST:
      draft.loaded = draft.loaded.filter((item) => item !== 'facetEntities');
      break;
    case actionTypes.FETCH_FACET_ENTITIES_SUCCESS:
      draft.sourceGroups[action.sourceGroup.id] = action.sourceGroup;
      draft.facetEntityItems[action.viewFacetId] = {
        sources: [],
        productHierarchies: [],
        productHierarchyGroups: [],
      };
      action.sources.forEach((newSource) => {
        draft.sources[newSource.id] = newSource;
        draft.facetEntityItems[action.viewFacetId].sources.push({
          key: newSource.id,
          value: newSource.id,
          label: newSource.preferred_name,
        });
      });
      action.productHierarchyGroups.forEach((productHierarchyGroup) => {
        draft.productHierarchyGroups[productHierarchyGroup.id] = {
          ...productHierarchyGroup,
          items: [],
        };
        draft.facetEntityItems[action.viewFacetId].productHierarchyGroups.push({
          key: productHierarchyGroup.id,
          value: productHierarchyGroup.id,
          label: productHierarchyGroup.name,
        });
      });

      // Manage product hierarchies
      if (!draft.productHierarchies) draft.productHierarchies = {};
      action.productHierarchies.forEach((newHierarchy) => {
        draft.productHierarchies[newHierarchy.id] = newHierarchy;
        if (newHierarchy.group_id)
          draft.productHierarchyGroups[newHierarchy.group_id].items.push({
            id: newHierarchy.id,
          });
      });
      draft.facetEntityItems[action.viewFacetId].productHierarchiesItems =
        action.productHierarchies.map((hierarchy) => ({
          key: hierarchy.id,
          value: hierarchy,
          parent: hierarchy.parent,
          label: hierarchy.full_name,
          text: hierarchy.full_name,
        }));
      // draft.productHierarchies = draft.productHierarchies;
      draft.loaded.push('productHierarchies');

      // We may push key `productHierarchies` twice, once when querying view and once when querying
      // product hierarchy search for facet creation.
      // No problem in doing that, but we may need different keys if we want to differentiate them
      draft.loaded.push(action.viewFacetId);
      break;
    case viewActionTypes.FETCH_VIEWS_SUCCESS:
      action.views[0].configuration.source_groups.forEach((item) => {
        draft.sourceGroups[item.id] = item;
      });
      draft.restitutionLanguages =
        action.views[0].configuration.restitution_languages;
      draft.loaded.push('sourceGroups');
      break;
    case entityGroupsActionTypes.CREATE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
      draft.productHierarchyGroups[action.productHierarchyGroup.id] =
        action.productHierarchyGroup;
      break;
    case actionTypes.FETCH_PRODUCT_HIERARCHIES_SUCCESS: {
      const hierarchies = draft.productHierarchies || {};
      action.productHierarchies.forEach((newHierarchy) => {
        hierarchies[newHierarchy.id] = newHierarchy;
      });
      draft.productHierarchiesItems = action.productHierarchies.map(
        (hierarchy) => ({
          key: hierarchy.id,
          value: hierarchy,
          parent: hierarchy.parent,
          label: hierarchy.full_name,
          text: hierarchy.full_name,
        })
      );
      draft.productHierarchies = hierarchies;
      draft.loaded.push('productHierarchies');
      break;
    }
    case entityGroupsActionTypes.FETCH_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
      action.productHierarchyGroups.forEach((productHierarchyGroup, i) => {
        draft.productHierarchyGroups[productHierarchyGroup.id] = {
          ...productHierarchyGroup,
          color: competitionEntityColors[i % competitionEntityColors.length],
        };
      });
      draft.loaded.push('productHierarchyGroups');
      break;
    case entityGroupsActionTypes.DELETE_PRODUCT_HIERARCHY_GROUPS_SUCCESS:
      delete draft.productHierarchyGroups[action.productHierarchyGroupId];
      break;
    case actionTypes.FETCH_TAG_SETS_SUCCESS:
      action.tagSets.forEach(({ id: tagSetId, name, items }) => {
        draft.tagSets[tagSetId] = { name, items };
        items.forEach(({ id, ...meta }) => {
          draft.tags[id] = meta;
        });
      });
      draft.loaded.push('tagSets');
      break;
    case actionTypes.FETCH_USERS_SUCCESS:
      draft.users = action.users || [];
      draft.loaded.push('users');
      break;
    case campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_SUCCESS:
      // Apply only to feedback campaigns
      if (isFeedbackCampaignConfiguration(action.campaignConfiguration)) {
        action.campaignConfiguration.search_ontology?.forEach(
          ({ db_id, ...item }) => {
            draft.concepts[db_id] = item;
          }
        );
      }
      break;
    case actionTypes.FETCH_ADMIN_ENTITIES_SUCCESS:
      draft.adminEntities = action.adminEntities;
      break;
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getInitialState();
    default:
      break;
  }
  return draft;
}, getInitialState());
