import { actionTypes } from 'actions/operations';
import { actionTypes as userActionTypes } from 'actions/user';

import produce from 'immer';

import { createLoadingSelector } from './ui';

const getOperationsInitialState = () => ({
  controllers: {},
  modules: [],
  module: null,
  tasks: [],
  // Track the last time we fetched tasks
  lastUpdateDate: null,
  controller: {},
  taskParameters: {},
  taskComment: null,
});

export default produce((draft, action) => {
  const initialState = getOperationsInitialState();
  switch (action.type) {
    case actionTypes.SET_CONTROLLER_MODULE:
      draft.module = action.module;
      draft.controller = initialState.controller;
      draft.taskParameters = initialState.taskParameters;
      draft.taskComment = initialState.taskComment;
      break;
    case actionTypes.SET_CONTROLLER:
      draft.controller = action.controller;
      draft.taskParameters = action.taskParameters;
      draft.taskComment = initialState.taskComment;
      break;
    case actionTypes.SET_TASK_PARAMETER:
      draft.taskParameters[action.name] = action.value;
      break;
    case actionTypes.SET_TASK_COMMENT:
      draft.taskComment = action.value;
      break;
    case actionTypes.FETCH_CONTROLLERS_SUCCESS:
      draft.controllers = action.controllers;
      draft.modules = Object.entries(action.controllers).map(
        ([moduleName, module]) => ({
          key: moduleName,
          value: moduleName,
          text: moduleName,
          subText: module.help,
        })
      );
      break;
    case actionTypes.FETCH_TASKS_SUCCESS:
      if (action.id) {
        const taskIndex = draft.tasks.findIndex(
          (item) => action.id === item.id
        );
        if (taskIndex) {
          draft.tasks[taskIndex] = action.tasks;
        }
      } else {
        draft.tasks = action.tasks;
      }
      // Save the last time we fetched tasks in a serialized format
      draft.lastUpdateDate = new Date().toISOString();
      break;
    case actionTypes.RUN_CONTROLLER_SUCCESS:
      // Uncomment to reset form after run
      // if (!action.dryRun) {
      //   draft.taskParameters = {};
      //   draft.controller = {};
      // }
      draft.tasks = [action.task, ...draft.tasks];
      break;
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getOperationsInitialState();
    default:
      break;
  }
  return draft;
}, getOperationsInitialState());

export const lastUpdateDateSelector = (state) =>
  state.operations.lastUpdateDate;

export const tasksLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_TASKS_REQUEST,
]);
