import { makeColorMap } from 'actions/entities';
import { actionTypes as userActionTypes } from 'actions/user';
import { actionTypes, compileAggregatesKey } from 'actions/view';

import produce from 'immer';

import * as svars from 'assets/style/variables';

const getVisualizationsInitialState = () => ({
  filters: {}, // we keep track of the filter values used to get these visualizations.
  n_reviews: 0,
  tree_map: [],
  word_cloud: {},
  currentWordCloud: {},
  currentTopReviews: {},
  currentConceptName: '',
});

const getViewFacetAggregatesInitialState = () => ({
  // Time series related visualizations (V2): keys: view facet id - values: visualizations (see commonProps)
  timeSeriesVisualizations: {},
  // Concept based visualizations (V2): mapping of view facet id # ontology name # concept_id # aggregates.
  conceptVisualizations: {},
  // Keep track of loaded time series related visualizations (V2): keys: view facet id - values: list of loaded aggregate types
  loadedAggregateTypes: [],
  // Map the loaded full reviews (when displayed). Refresh at every search
  reviews: {},
  // Results (with pagination and statitics) for search tab
  searchResults: {
    reviewChunks: [],
    statistics: {},
    pagination: {},
  },
  // Per ontology/concept Wordclouds (v2)
  wordClouds: {},
  isEmpty: undefined,
  // Entity colors
  colors: {},
});

const getViewsInitialState = () => ({
  views: [],
  // If things get more complex we could use a separate (nested) visualizations reducer.
  visualizations: getVisualizationsInitialState(),

  // Map the loaded full reviews (when displayed). Refresh at every search
  reviews: {},

  // REFACTOR - move v2 aggregates into view facet id indexed object
  viewFacetAggregates: {},

  // A map of view facet id to filter preset list
  viewFacetFilterPresets: {},

  // Analysis view facet
  // NB : we don't use the listed view facet as they don't contain all required fields
  viewFacet: null,
});

const getOrCreateFacetAggregates = (state, viewFacetId) => {
  if (!state.viewFacetAggregates[viewFacetId]) {
    state.viewFacetAggregates[viewFacetId] =
      getViewFacetAggregatesInitialState();
  }
  return state.viewFacetAggregates[viewFacetId];
};

const markLoadedAggregates = (
  draft,
  viewFacetId,
  aggregateType,
  conceptId = null
) => {
  draft.viewFacetAggregates[viewFacetId].loadedAggregateTypes.push(
    compileAggregatesKey(aggregateType, conceptId)
  );
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VIEWS_SUCCESS:
      draft.views = action.views;
      // As for now, as there is one view per user, we automatically set the current view to the unique
      // view for the user.
      if (draft.views.length === 1) {
        [draft.currentView] = draft.views;
      }
      break;
    // V2 related
    case actionTypes.FETCH_ONE_VIEW_FACET_REQUEST: {
      if (action.reset) {
        draft.viewFacet = undefined;
        draft.visualizations = getVisualizationsInitialState();
      }
      break;
    }
    case actionTypes.FETCH_ONE_VIEW_FACET_SUCCESS: {
      draft.viewFacet = action.viewFacet;
      if (!action.initializeGroups) break;

      const aggregates = getOrCreateFacetAggregates(draft, action.viewFacet.id);
      if (action.viewFacet.comparative_product_hierarchy_groups) {
        aggregates.colors = makeColorMap(
          action.viewFacet.comparative_product_hierarchy_groups,
          svars.competitionEntityColors
        );
      }
      aggregates.colors[action.viewFacet.base_product_hierarchy_group.id] =
        svars.accentColorClear;
      break;
    }
    case actionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_SUCCESS: {
      const aggregates = getOrCreateFacetAggregates(draft, action.viewFacetId);
      Object.entries(action.visualizations).forEach(([key, data]) => {
        aggregates.timeSeriesVisualizations[key] = data;
      });
      if (action.aggregateType === 'kpi') {
        // We query this aggregate first to make sure analysis is not empty
        if (!aggregates.timeSeriesVisualizations.kpis.kpis) {
          aggregates.isEmpty = true;
        } else {
          aggregates.isEmpty = false;
        }
      }
      markLoadedAggregates(draft, action.viewFacetId, action.aggregateType);
      break;
    }
    case actionTypes.FETCH_CONCEPT_AGGREGATES_SUCCESS: {
      const aggregates = getOrCreateFacetAggregates(draft, action.viewFacetId);

      if (!aggregates.conceptVisualizations[action.conceptId]) {
        aggregates.conceptVisualizations[action.conceptId] = {};
      }
      aggregates.conceptVisualizations[action.conceptId] = {
        ...aggregates.conceptVisualizations[action.conceptId],
        ...action.aggregates,
      };
      markLoadedAggregates(
        draft,
        action.viewFacetId,
        action.aggregateType,
        action.conceptId
      );
      break;
    }
    case actionTypes.RESET_VIEW_FACET_AGGREGATES:
      (action.viewFacetIds || [action.viewFacetId]).forEach(
        (id) => delete draft.viewFacetAggregates[id]
      );
      break;
    case actionTypes.FETCH_WORDCLOUD_V2_SUCCESS: {
      const aggregates = getOrCreateFacetAggregates(draft, action.viewFacetId);

      aggregates.wordClouds[action.conceptId] = action.wordCloud;
      markLoadedAggregates(
        draft,
        action.viewFacetId,
        action.aggregateType,
        action.conceptId
      );
      break;
    }
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getViewsInitialState();
    default:
      break;
  }
  return draft;
}, getViewsInitialState());
