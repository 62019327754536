import { actionTypes } from 'actions/facet';
import { actionTypes as userActionTypes } from 'actions/user';

import produce from 'immer';

export const getviewFacetsInitialState = () => ({
  viewFacets: undefined,
  loaded: [],
});

export default produce((draft, action) => {
  let viewFacetIndex;
  switch (action.type) {
    case actionTypes.FETCH_VIEW_FACETS_SUCCESS:
      draft.viewFacets = action.viewFacets;
      draft.loaded.push('viewFacets');
      break;
    case actionTypes.UPDATE_VIEW_FACET_SUCCESS:
      viewFacetIndex = draft.viewFacets.findIndex(
        ({ id }) => action.viewFacet.id === id
      );
      if (viewFacetIndex >= 0) draft.viewFacets.splice(viewFacetIndex, 1);
      draft.viewFacets.unshift(action.viewFacet);
      break;
    case actionTypes.CREATE_VIEW_FACET_SUCCESS:
      draft.viewFacets.unshift(action.viewFacet);
      break;
    case actionTypes.DELETE_VIEW_FACET_SUCCESS:
      draft.viewFacets = draft.viewFacets.filter(
        ({ id }) => id !== action.viewFacetId
      );
      break;
    case actionTypes.SET_VIEW_FACET_AS_FAVORITE_SUCCESS:
      viewFacetIndex = draft.viewFacets.findIndex(
        ({ id }) => action.viewFacetId === id
      );
      draft.viewFacets[viewFacetIndex].favorite = action.value;
      break;
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getviewFacetsInitialState();
    default:
      break;
  }
  return draft;
}, getviewFacetsInitialState());
