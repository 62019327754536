import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { endOfWeek, startOfWeek } from 'date-fns';
import styled from 'styled-components';

import { formatDate } from 'reducers/locale';

import moment from 'moment';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';
import { dateFormat, fnsDateTimeFormat } from 'assets/style/variables';

import { getAbbreviation } from '../components/customer/periodTypes';

const NUMBER_LOCALE = 'en';

export const dateFormatter = (isoOrUnixTime, { outputFormat = 'MM/y' } = {}) =>
  formatDate(new Date(isoOrUnixTime), outputFormat);
export const monthFormatter = (isoOrUnixTime) =>
  dateFormatter(isoOrUnixTime, { outputFormat: 'MM/y' });

export const weekFormatter = (isoOrUnixTime) => {
  const date = new Date(isoOrUnixTime);
  return `${formatDate(startOfWeek(date), 'dd/MM')} - ${formatDate(
    endOfWeek(date),
    'dd/MM'
  )}`;
};

export const dayFormatter = (isoOrUnixTime) =>
  dateFormatter(isoOrUnixTime, { outputFormat: 'dd/MM/yy' });
export const longDayFormatter = (isoOrUnixTime) =>
  dateFormatter(isoOrUnixTime, { outputFormat: 'dd/MM/yyyy' });

export const dateTimeFormatter = (isoOrUnixTime) =>
  dateFormatter(isoOrUnixTime, { outputFormat: fnsDateTimeFormat });

export const numberFormatter = (number) =>
  typeof number === 'number'
    ? new Intl.NumberFormat(NUMBER_LOCALE).format(number)
    : number;

export const precisionFloatFormatter =
  (precision = 2) =>
  (someFloat) =>
    typeof someFloat === 'number'
      ? new Intl.NumberFormat(NUMBER_LOCALE, {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        }).format(
          someFloat < 0 && someFloat * 10 ** precision > -0.5
            ? -someFloat
            : someFloat
        )
      : '-';

export const floatFormatter = precisionFloatFormatter(2);
export const onePrecisionFloatFormatter = precisionFloatFormatter(1);
export const zeroPrecisionFloatFormatter = precisionFloatFormatter(0);

export const percentFormatter = (someFloat) =>
  `${onePrecisionFloatFormatter(someFloat)}%`;

export const zeroPrecisionPercentFormatter = (someRatioFloat) =>
  (someRatioFloat < 0.01 && someRatioFloat > -0.01 && '< 1%') ||
  `${zeroPrecisionFloatFormatter(someRatioFloat * 100)}%`;

export const contributionPercentFormatter = (value) =>
  percentFormatter(100 * Math.abs(value));

export const roundWithPrecision = (number, precision = 2) => {
  const multiplier = 10 ** precision;
  return Math.round(number * multiplier) / multiplier;
};

export const formatFacetDates = (minDate, maxDate) =>
  [
    minDate ? moment(minDate).format(dateFormat) : '...',
    '\u21D4',
    maxDate ? moment(maxDate).format(dateFormat) : '...',
  ].join(' ');

export const formatPeriod = (viewFacet) => {
  const abbreviation = getAbbreviation(viewFacet.period_type, viewFacet.period);
  const datesFormatted = formatFacetDates(
    viewFacet.min_date,
    viewFacet.max_date
  );
  return abbreviation ? `${datesFormatted} (${abbreviation})` : datesFormatted;
};

export const getTimezoneUnawareDateString = (date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];

export const incrementFormatterFactory = (formatter) => (value) =>
  `${value > 0 ? '+' : ''}${formatter(value)}`;

const IndicatorContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  min-width: 25px;
`;

export const numberOnHundredFormatter = (
  value,
  denominatorFontSize = svars.fontSizeLarge
) => (
  <span>
    <IndicatorContainer>
      {value === null ? '-' : numberFormatter(value)}
    </IndicatorContainer>
    <span style={{ fontSize: denominatorFontSize }}> /100</span>
  </span>
);

const polarityMap = {
  1: msg({ id: "positive" }),
  0: msg({ id: "neutral" }),
  '-1': msg({ id: "negative" }),
};

export const polarityFormatter = (value) =>
  capitalize(i18n._(polarityMap[value]));
