// a function to retry loading a chunk to avoid chunk load error for out of date code
async function lazyImportWithRetry(componentImport, name) {
  const sessionKey = `retry-lazy-refreshed-${name}`;
  // Make sure that sessionKey is set to false, for a new session
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionKey) || 'false'
    );
    // try to import the component
    componentImport()
      .then((imported) => {
        window.sessionStorage.setItem(sessionKey, 'false'); // success so reset the refresh
        resolve(imported);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(sessionKey, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        return reject(error); // Default error behaviour as already tried refresh
      });
  });
}

export default lazyImportWithRetry;
