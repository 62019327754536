import config from 'config';
import { range } from 'utils/helpers';

import * as svars from 'assets/style/variables';

/**
 * Transform hexadecimal color string to RGB values.
 *
 * @param {String} hex the hex color
 * @returns an object with properties r, g, b
 */
const hexToRgb = (hex) => {
  const rgbValues = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] };
};

/**
 * Transform RGB values to hexadecimal color string
 *
 * @param {Number} { r, g, b } the RGB values
 * @returns an hex color string
 */
const rgbToHex = ({ r, g, b }) =>
  `#${[Math.round(r), Math.round(g), Math.round(b)]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;

// Linear interpolation is the purest method to use.
// Cosine interpolation pushes low values to midColor
// Having mid color closer to white helps keeping a larger color palette
export const linearInterpolation = (start, end, ratio) =>
  start * ratio + end * (1 - ratio);
/**
 * Cosine interpolation.
 *
 * @param {*} start start point
 * @param {*} end end point
 * @param {*} ratio start to end ratio
 * @returns the interpolation of start and end weight by ratio
 */
export const cosineInterpolation = (start, end, ratio) => {
  const ft = ratio * Math.PI;
  const f = (1 - Math.cos(ft)) * 0.5;
  return start * f + end * (1 - f);
};

const interpolateColors = (
  minColor,
  maxColor,
  colorRatio,
  interpolateMethod
) => {
  const minColorRgb = hexToRgb(minColor);
  const maxColorRgb = hexToRgb(maxColor);
  const interpolatedColorRgb = {
    r: interpolateMethod(minColorRgb.r, maxColorRgb.r, colorRatio),
    g: interpolateMethod(minColorRgb.g, maxColorRgb.g, colorRatio),
    b: interpolateMethod(minColorRgb.b, maxColorRgb.b, colorRatio),
  };
  return rgbToHex(interpolatedColorRgb);
};

export const getInterpolatedColor = (
  value,
  interpolation = cosineInterpolation,
  minColor = svars.absoluteMinColor,
  maxColor = svars.absoluteMaxColor,
  midColor = svars.absoluteMidColor
) => {
  let color;
  let normalizedValue;
  if (value > 0) {
    // between mid and max
    // ratio is distance to mid color (as min color in gradient mid to max)
    // by dividing by max sentiment
    normalizedValue = value / Math.abs(config.SENTIMENT_DOMAIN[1]);
    color = interpolateColors(
      maxColor,
      midColor,
      normalizedValue,
      interpolation
    );
  } else if (value < 0) {
    // between min and mid
    // ratio is distance to mid color (as min color in gradient mid to max)
    // value + 1 : we translate value from [-1, 0] to [0, 1]
    // by dividing by min sentiment
    normalizedValue = -value / Math.abs(config.SENTIMENT_DOMAIN[0]);
    color = interpolateColors(
      minColor,
      midColor,
      normalizedValue,
      interpolation
    );
  } else {
    color = svars.absoluteMidColor;
  }
  return color;
};

/**
 * Build a list of optimal gradient colors based on a min and max colors, and a number of colors to generate.
 * This helps to associate a color to an entity dynamically.
 *
 * @param {String} minColor The (hex) first color of the gradient.
 * @param {String} maxColor The (hex) last color of the gradient.
 * @param {Number} nColors The (hex) number of colors to generate.
 */
export const getGradientNColors = (minColor, maxColor, nColors) => {
  const ratioStep = 1 / (nColors - 1);
  return range(nColors).map((iColor) =>
    interpolateColors(
      minColor,
      maxColor,
      iColor * ratioStep,
      linearInterpolation
    )
  );
};

export const getSentimentKpiColor = (value) => {
  if (typeof value === 'number') {
    return (
      (value > 0 && svars.absoluteMaxColor) ||
      (value < 0 && svars.absoluteMinColor) ||
      svars.absoluteMidColorStroke
    );
  }
  return 'inherit';
};

export const getIncreaseColor = (increase, isAbsolute) => {
  if (typeof increase === 'number') {
    const threshold = -0.005 / (isAbsolute ? 1 : 100);
    return increase > threshold ? svars.colorSuccess : svars.colorDanger;
  }
  return 'inherit';
};

export /**
 * Get categorical sentiment color (hex code) for a given numerical sentiment
 *
 * @param {number} sentiment
 * @param {number} [neutralThreshold=0.25]
 * @param {number} [midColorThreshold=0.7]
 */
const getSentimentCategoryColor = (
  sentiment,
  neutralThreshold = 0.25,
  midColorThreshold = 0.7
) =>
  (sentiment > midColorThreshold && svars.absoluteMaxColor) ||
  (sentiment > neutralThreshold && svars.absoluteMidPositiveColor) ||
  (sentiment >= -neutralThreshold && svars.absoluteMidColorStroke) ||
  (sentiment >= -midColorThreshold && svars.absoluteMidNegativeColor) ||
  svars.absoluteMinColor;

export /**
 * Get CSAT score color (hex code) for a given numerical score.
 *
 * @param {*} score
 */
const getCSATScoreColor = (score) =>
  score != null
    ? getSentimentCategoryColor((score - 50) / 50, 0.2, 0.6)
    : svars.colorGreyMedium;
