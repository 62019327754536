import { objectMap, zip } from 'utils/helpers';

/**
 * Transform API formatted data into list of objects with arbitrary level of nesting.
 *
 * @param {Object} aggregates the API formatted data.
 * @param {Integer} nestLevel the nesting level of the aggregates.
 *     - If nestLevel = 0 : `data` is a first level key of aggregates;
 *     - If nestLevel = 1 : `data` is a key of aggregates values;
 *     - If nestLevel = 2 : `data` is a key of the values of aggregates values ...
 */
export const processApiVisualizationData = (aggregates, nestLevel = 0) => {
  if (nestLevel) {
    return objectMap(aggregates.data, (nestedVisualizations) =>
      processApiVisualizationData(
        { data: nestedVisualizations, columns: aggregates.columns },
        nestLevel - 1
      )
    );
  }
  if (!(aggregates && aggregates.data && Object.keys(aggregates.data))) {
    return null;
  }
  const data = aggregates.data.map((item) =>
    zip(aggregates.columns, item).reduce((accumulatingObject, [key, value]) => {
      accumulatingObject[key] = value;
      return accumulatingObject;
    }, {})
  );
  return data;
};

/**
 * Fetch visualizations aggregates based on a facet id and an aggregate type.
 *
 * @param {String} viewFacetId the id of the facet.
 * @param {String} aggregateType type of aggregate as defined in the api.
 */
const parseFacetAggregates = (responseData, aggregateType) => {
  const aggregates = {};
  switch (aggregateType) {
    case 'ts':
      aggregates.timeSeries = {
        monthly: responseData.data
          ? processApiVisualizationData(responseData.data.monthly)
          : null,
      };
      break;
    case 'concept_kpi':
      aggregates.conceptKpis = responseData.data
        ? processApiVisualizationData(responseData.data.kpis, 2)
        : null;
      break;
    case 'general_dist':
      // NB: we cannot use nesting here because distributions don't have the same columns
      // (they are not nested aggregates as defined in the backend)
      aggregates.generalDistributions = responseData.data
        ? objectMap(responseData.data, processApiVisualizationData)
        : null;
      break;
    case 'kpi':
      aggregates.kpis = {
        kpis: responseData?.data?.kpis
          ? processApiVisualizationData(responseData.data.kpis)
          : null,
        increases: responseData.data
          ? objectMap(responseData.data.increases, processApiVisualizationData)
          : null,
      };
      break;
    case 'competition_kpi':
      aggregates.competitionKpis = {
        kpis: responseData?.data?.kpis
          ? processApiVisualizationData(responseData.data.kpis)
          : null,
        benchmarkKpis:
          responseData.data && responseData.data.benchmark_kpis
            ? processApiVisualizationData(responseData.data.benchmark_kpis)
            : null,
        increases:
          responseData.data && responseData.data.increases
            ? objectMap(
                responseData.data.increases,
                processApiVisualizationData
              )
            : null,
      };
      break;
    case 'competition_concept_kpi':
      aggregates.competitionConceptKpis = {
        kpis: responseData?.data?.kpis
          ? processApiVisualizationData(responseData.data.kpis, 1)
          : null,
        top: responseData?.data?.top
          ? processApiVisualizationData(responseData.data.top)
          : null,
        bottom: responseData?.data?.bottom
          ? processApiVisualizationData(responseData.data.bottom)
          : null,
        benchmark_kpis: responseData?.data?.benchmark_kpis
          ? processApiVisualizationData(responseData.data.benchmark_kpis, 2)
          : null,
      };
      break;
    case 'concept_wordcloud':
      aggregates.conceptWordcloud = responseData.data
        ? responseData.data
        : null;
      break;
    case 'product_kpi':
      aggregates.productHierarchyKpis = {
        kpis: responseData?.data?.kpis
          ? processApiVisualizationData(responseData.data.kpis)
          : null,
      };
      break;
    case 'tag_kpi':
      aggregates.tagKpis = {
        kpis:
          responseData.data && Object.keys(responseData.data).length
            ? processApiVisualizationData(responseData.data, 1)
            : null,
      };
      break;
    case 'competition_ts':
      aggregates.competitionTimeSeries = {
        monthly: responseData?.data?.monthly
          ? processApiVisualizationData(responseData.data.monthly, 1)
          : null,
      };
      break;
    case 'top_bottom_concept':
      aggregates.topBottomConcept = {
        top: responseData?.data?.top
          ? objectMap(responseData.data.top, processApiVisualizationData)
          : null,
        bottom: responseData?.data?.bottom
          ? objectMap(responseData.data.bottom, processApiVisualizationData)
          : null,
      };
      break;
    // Concept based aggregates
    case 'concept_product_kpi':
      aggregates.productKpis = responseData.data
        ? processApiVisualizationData(responseData.data)
        : null;
      break;
    case 'concept_source_kpi':
      aggregates.sourceKpis = responseData.data
        ? processApiVisualizationData(responseData.data)
        : null;
      break;
    case 'concept_tag_kpi':
      aggregates.tagKpis = {
        kpis: responseData.data
          ? processApiVisualizationData(responseData.data, 1)
          : null,
      };
      break;
    case 'concept_ts':
      aggregates.conceptTimeSeries = responseData.data
        ? processApiVisualizationData(responseData.data)
        : null;
      break;
    case 'competition_concept_ts':
      aggregates.competitionConceptTimeSeries = responseData.data
        ? processApiVisualizationData(responseData.data, 1)
        : null;
      break;
    case 'wc':
      aggregates.wordCloud = responseData.data;
      break;
    default:
      break;
  }
  return aggregates;
};

export default parseFacetAggregates;
