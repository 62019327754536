import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const scrollToTop = () =>
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth',
  });

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    scrollToTop();
  }, [location]);

  return null;
}
ScrollToTop.propTypes = {};

export default ScrollToTop;
