import { actionTypes } from 'actions/ui';

import produce from 'immer';

const getInitialState = () => ({
  isLoading: false,
  isLoadingEnabled: true,
  supportModalOpen: false,
});

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      draft.isLoading = true;
      break;
    case actionTypes.FINISH_LOADING:
      draft.isLoading = false;
      break;
    case actionTypes.TOGGLE_LOADING_ENABLED:
      draft.isLoading = false;
      draft.isLoadingEnabled = !draft.isLoadingEnabled;
      break;
    case actionTypes.TOGGLE_SUPPORT_MODAL:
      draft.supportModalOpen = !draft.supportModalOpen;
      break;
    default:
      break;
  }
  return draft;
}, getInitialState());

/**
 * Return a selector that takes the loading state and return a logical OR of actions loading status.
 * @param actions: array of action types (strings).
 */
export const createLoadingSelector = (actions) => (state) =>
  actions.reduce((anyLoading, action) => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
    const [, requestName, ..._] = matches;
    return anyLoading || !!state.loading?.[requestName];
  }, false);

/**
 * https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 *
 * Following a convention for action names we can have a generic loading reducer.
 * All async actions are called with the same _REQUEST _SUCCESS _FAILURE suffixes.
 *
 */
// eslint-disable-next-line default-param-last
export const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  };
};

export const loadingStateSelector = (types) => (state) =>
  createLoadingSelector(types)(state);
