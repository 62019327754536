

import { readableColor } from 'polished';

import { splitTextLabel } from 'utils/helpers';

import {
  colorWhite,
  fontColorBase,
  svgCharLength,
  textSpanHeight,
} from 'assets/style/variables';

const makeTspans = (texts, originY, tspanHeight, spanProps) => {
  const makeTspan = (tText, tY) => (
    <tspan key={`tspan-${tY}-${tText}`} {...spanProps} y={tY}>
      {tText}
    </tspan>
  );
  return [
    makeTspan(texts[0], originY),
    ...texts
      .slice(1)
      .map((textSplit, i) =>
        makeTspan(textSplit, originY + (i + 1) * tspanHeight)
      ),
  ];
};

export const renderSvgTextSpans = (
  text,
  x0,
  x1,
  y0,
  y1,
  sentimentColor = null,
  centered = false,
  extraProps = {},
  // Force text to be within the box given by coordinates
  forceBoxLimit = true,
  charLength = svgCharLength,
  tspanHeight = textSpanHeight
) => {
  const centerY = y0 + (y1 - y0) / 2;
  const originY = centered ? centerY : y0;
  const width = x1 - x0;
  const height = y1 - y0;
  const textColor = sentimentColor
    ? readableColor(sentimentColor, fontColorBase, colorWhite)
    : fontColorBase;
  const originX = centered ? x0 + width / 2 : x0;
  const spanProps = { ...extraProps, x: originX, fill: textColor };
  const nCharsLimit = Math.round(width / charLength);
  const splittedTexts = splitTextLabel(text, nCharsLimit, true);
  const nDisplayedTexts = Math.min(
    splittedTexts.length,
    Math.floor(height / tspanHeight)
  );
  // If space is insufficient, return '...' unicode character:
  // - if we cannot display any text row, or;
  // - If we cannot display more than 4 characters, or;
  // - if we cannot display the complete first word of `text`.
  if (
    nDisplayedTexts < 1 ||
    nCharsLimit < 2 ||
    (text && nCharsLimit < text.split(' ')[0].length)
  ) {
    return makeTspans(['\u2026'], originY, tspanHeight, spanProps);
  }

  const displayedTexts = splittedTexts.slice(0, nDisplayedTexts);
  // If text has been remove, add ellipsis
  if (displayedTexts.length < splittedTexts.length) {
    displayedTexts[nDisplayedTexts - 1] = `${
      displayedTexts[nDisplayedTexts - 1]
    } \u2026`;
  }
  let firstTextY = originY - tspanHeight * Math.floor(nDisplayedTexts / 2);
  if (forceBoxLimit) {
    // if `forceBoxLimit` is set, take the maximum between computed value and the top of the box with a padding of one text span width
    firstTextY = Math.max(y0 + tspanHeight, firstTextY);
  }
  return makeTspans(displayedTexts, firstTextY, tspanHeight, spanProps);
};

export const svgShadowDef = (
  <defs>
    <filter id="boxShadow" x="0" y="0" width="200%" height="200%">
      <feOffset result="offOut" in="SourceGraphic" dx="3" dy="5" />
      <feColorMatrix
        result="matrixOut"
        in="offOut"
        type="matrix"
        values="0.2 0 0 0 0
                0 0.2 0 0 0
                0 0 0.2 0 0
                0 0 0 0.5 0"
      />
      <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="6" />
      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
    </filter>
  </defs>
);

// Svg defs - 88% opacity white outline around svg text
export const svgWhiteOutlineDef = (
  <defs>
    <filter id="whiteOutlineEffect" colorInterpolationFilters="sRGB">
      <feMorphology
        in="SourceAlpha"
        result="MORPH"
        operator="dilate"
        radius="1.9"
      />
      <feColorMatrix
        in="MORPH"
        result="WHITENED"
        type="matrix"
        values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 0.88 0"
      />
      <feMerge>
        <feMergeNode in="WHITENED" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);
