import { actionTypes, getInitialSearchFilters } from 'actions/search';
import { actionTypes as userActionTypes } from 'actions/user';

import produce from 'immer';

const getViewFacetSearchInitialState = () => ({
  ...getInitialSearchFilters(),
  shouldUpdateSearch: false,
  selectedPreset: null,
  focusReviewId: null,
  // Results (with pagination and statitics) for search tab
  searchResults: {
    reviewChunks: [],
    statistics: {},
    pagination: {},
  },
  // Map the loaded full reviews (loaded when displaying in modal).
  reviews: {},
});

const getSearchInitialState = () => ({
  // Map view facet id to search results state
  viewFacetSearch: {},
  // A map of view facet id to filter preset list
  viewFacetFilterPresets: {},
});

export const getOrCreateFacetSearch = (state, viewFacetId) => {
  if (!state.viewFacetSearch[viewFacetId]) {
    state.viewFacetSearch[viewFacetId] = getViewFacetSearchInitialState();
  }
  return state.viewFacetSearch[viewFacetId];
};

export const getFilterPresets = (state, viewFacetId) =>
  state.viewFacetFilterPresets[viewFacetId] || [];

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_FACET_SEARCH: {
      getOrCreateFacetSearch(draft, action.viewFacetId);
      break;
    }
    case actionTypes.UPDATE_SEARCH_FILTERS: {
      // Trying to use formatted payload from action, but it may generate too much redux events

      if (action.isTag) {
        draft.viewFacetSearch[action.viewFacetId].filters.tags =
          action.newSearchState;
      } else {
        draft.viewFacetSearch[action.viewFacetId].filters =
          action.newSearchState;
      }
      draft.viewFacetSearch[action.viewFacetId].shouldUpdateSearch =
        action.shouldUpdateSearch;
      break;
    }
    case actionTypes.FOCUS_REVIEW_CHUNK:
      // Make sure the facet search is initialized before setting focused chunk
      if (draft.viewFacetSearch[action.viewFacetId]) {
        draft.viewFacetSearch[action.viewFacetId].focusReviewChunk =
          action.reviewChunk;
      }
      break;
    case actionTypes.FETCH_REVIEW_SUCCESS:
      draft.viewFacetSearch[action.viewFacetId].reviews[action.reviewId] =
        action.review;
      break;
    case actionTypes.SEARCH_REVIEW_CHUNKS_SUCCESS: {
      const facetSearch = getOrCreateFacetSearch(draft, action.viewFacetId);

      facetSearch.searchResults.reviewChunks = action.reviewChunks;
      facetSearch.searchResults.statistics = action.statistics;
      facetSearch.searchResults.pagination = {
        ...facetSearch.searchResults.pagination,
        ...action.pagination,
      };
      draft.viewFacetSearch[action.viewFacetId].shouldUpdateSearch = false;
      break;
    }
    case actionTypes.PAGINATE_REVIEW_CHUNKS_SUCCESS: {
      const facetSearch = getOrCreateFacetSearch(draft, action.viewFacetId);
      facetSearch.searchResults.reviewChunks = [
        ...facetSearch.searchResults.reviewChunks,
        ...action.reviewChunks,
      ];
      facetSearch.searchResults.pagination.page = action.pagination.page;
      break;
    }
    case actionTypes.SWITCH_DATE_SORT: {
      const facetSearch = getOrCreateFacetSearch(draft, action.viewFacetId);
      facetSearch.sortDateAsc = action.sortDateAsc;
      break;
    }

    case actionTypes.FETCH_FILTER_PRESETS_SUCCESS: {
      draft.viewFacetFilterPresets[action.viewFacetId] = action.presets;
      break;
    }
    case actionTypes.SAVE_FILTER_PRESETS_SUCCESS: {
      let baseList = getFilterPresets(draft, action.viewFacetId);
      if (action.isUpdate) {
        baseList = baseList.filter(({ id }) => action.preset.id !== id);
      }
      draft.viewFacetFilterPresets[action.viewFacetId] = [
        action.preset,
        ...baseList,
      ];
      break;
    }
    case actionTypes.SELECT_FILTER_PRESET: {
      getOrCreateFacetSearch(draft, action.viewFacetId).selectedPreset =
        action.value;
      break;
    }
    case actionTypes.DELETE_FILTER_PRESETS_SUCCESS:
      draft.viewFacetFilterPresets[action.viewFacetId] = getFilterPresets(
        draft,
        action.viewFacetId
      ).filter(({ id }) => action.presetId !== id);
      break;
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getSearchInitialState();
    default:
      break;
  }
  return draft;
}, getSearchInitialState());
