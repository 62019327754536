import { createSelector } from '@reduxjs/toolkit';

export const getAppConfigValue = (state, valueKey, formatter = null) => {
  const value = state.user.appConfig ? state.user.appConfig[valueKey] : null;
  return value ? formatter(value) : value;
};

export const appConfigSelector = (state) => state.user.appConfig;
export const analysisAuthorizedSelector = createSelector(
  appConfigSelector,
  (appConfig) => appConfig.analysis
);
export const campaignAuthorizedSelector = createSelector(
  appConfigSelector,
  (appConfig) => appConfig.campaign
);
export const analysisLanguageSelector = createSelector(
  appConfigSelector,
  (appConfig) => appConfig.ANALYSIS_LANGUAGE
);
export const nChunksLimitSelector = createSelector(
  appConfigSelector,
  (appConfig) => appConfig.n_chunks_export_limit
);
export const isAuthenticatedSelector = (state) => state.user.isAuthenticated;
export const isAdminSelector = (state) => state.user.user.isAdmin;
export const isBwSelector = (state) =>
  state.user.user.isCsm || state.user.user.isAdmin;

export const currentUserSelector = (state) => state.user.user;
export const usernameSelector = (state) => state.user.user.username;
export const pseudonymSelector = (state) => state.user.user.pseudonym;
