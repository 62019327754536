import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { i18n } from '@lingui/core';

import { campaignNameSelector } from 'selectors/campaign';
import { periodFilterSelector } from 'store/monitor/monitorSearchSlice';

import { DATE_PERIODS } from 'components/customer/campaign/monitor/MonitorPeriodSelect';

import { capitalize } from 'utils/helpers';

const useGetCampaignExportTitle = (campaignId) => {
  const campaignName = useSelector(campaignNameSelector(campaignId));
  const period = useSelector(periodFilterSelector);
  const periodString = period
    ? i18n._(DATE_PERIODS.find(({ value }) => value === period).textId)
    : '';
  return useCallback(
    () => (suffix) =>
      capitalize(
        `${campaignName} - ${periodString}${suffix ? ` - ${suffix}` : ''}`
      ),
    [campaignId, periodString]
  );
};

export default useGetCampaignExportTitle;
