import { actionTypes as campaignActionTypes } from 'actions/campaign';
import { actionTypes as surveyActionTypes } from 'actions/survey';
import { createLoadingSelector } from 'reducers/ui';

// Used mostly to make sure draft automatic save does not happen when a request
// than can change the draft version is in progress
export const campaignDraftLoadingSelector = createLoadingSelector([
  campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
  campaignActionTypes.RESET_PREVIEW_CONFIG_REQUEST,
  campaignActionTypes.SAVE_CAMPAIGN_CONFIGURATION_REQUEST,
  campaignActionTypes.UPDATE_PREVIEW_CONFIG_REQUEST,
  campaignActionTypes.ADD_FAQ_ITEM_REQUEST,
  surveyActionTypes.ADD_QUESTION_REQUEST,
  surveyActionTypes.DUPLICATE_QUESTION_REQUEST,
  surveyActionTypes.ADD_OPTION_REQUEST,
]);

export const campaignConfigurationIsLoadingSelector = createLoadingSelector([
  campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
  campaignActionTypes.RESET_PREVIEW_CONFIG_REQUEST,
  campaignActionTypes.SAVE_CAMPAIGN_CONFIGURATION_REQUEST,
  campaignActionTypes.UPDATE_PREVIEW_CONFIG_REQUEST,
]);
